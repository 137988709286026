<template>
  <div class="list" @click="selectCard()">
    <div class="informacao">
      <div class="nome">
        <div
          v-html="highlight(modelo.Nome)"
          v-if="modelo.Nome != undefined"
        ></div>
        <div v-html="highlight(modelo.Valor)" v-else></div>
      </div>
      <div class="linha"></div>

      <div class="descricao--shrink">
        <div v-html="highlight(modelo.Descricao)"></div>
      </div>
    </div>
    <span class="fav-box">
      <div class="checkbox">
        <div v-if="modelo">
          <input
            class="input"
            :checked="verificaSelecao()"
            type="checkbox"
            :disabled="desabilitaAlerta && modelo.Valor == 'BOXSUPPJ009'"
            :class="{
              disable: desabilitaAlerta && modelo.Valor == 'BOXSUPPJ009',
            }"
            @change="selectCard"
            @click="selectCard"
          />
        </div>
      </div>

      <span
        class="fav -not"
        v-if="
          !modelosPreferidos.includes(modelo.Valor) &&
          !modelo.Valor.startsWith('BOXSUPP') &&
          !modelo.Valor.includes('KYC')
        "
        @click="
          trocarFavorito(modelo);
          $event.stopPropagation();
        "
      >
        <img src="/img/selecao-produtos/estrela-n.png" alt="" class="fav-img" />
      </span>
      <span
        class="fav"
        v-else-if="
          modelosPreferidos.includes(modelo.Valor) &&
          !modelo.Valor.startsWith('BOXSUPP') &&
          !modelo.Valor.includes('KYC')
        "
        @click="
          trocarFavorito(modelo);
          $event.stopPropagation();
        "
      >
        <img
          src="/img/selecao-produtos/estrela-preenchida.png"
          alt=""
          class="fav-img"
        />
      </span>
    </span>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState } from "pinia";

export default {
  name: "CardModeloLista",

  props: {
    modelo: Object,
    search: String,
    tags: Array,
    modeloSelecionado: Object,
    consultasSelecionadas: Array,
    modelosPreferidos: Array,
    desabilitaAlerta: Boolean,
  },

  components: {},

  data() {
    return {};
  },

  methods: {
    highlight(texto) {
      if (!this.search) {
        return texto;
      }

      return texto.replace(new RegExp(this.search, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },

    verificaSelecao() {
      if (this.consultasSelecionadas == undefined) {
        return this.modeloSelecionado === this.modelo;
      } else {
        return (
          this.modeloSelecionado === this.modelo ||
          this.consultasSelecionadas.some(
            (consulta) => consulta.Valor === this.modelo.Valor
          )
        );
      }
    },

    selectCard() {
      this.$emit("selecionaModelo", this.modelo);
    },

    trocarFavorito(produto) {
      this.$emit("trocarFavorito", produto);
    },
  },

  mounted() {},

  computed: {
    // selecionado() {
    //   if (this.produto.Selecionado) {
    //     return true;
    //   }
    //   return false;
    // },
    // resultadoPesquisa() {
    //   if (this.produto.Pesquisado) {
    //     return true;
    //   }
    //   return false;
    // },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.list {
  width: 100%;
  background: transparent;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  padding: 5px;
  line-height: 18px;
  cursor: pointer;
}

.informacao {
  padding: 5px;
  width: 100%;
  // height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $outbox-medium-blue;
  margin: 0 10px;
  color: #86878f;

  // &.pesquisado {
  //   border: 2px solid #61c29c;
  //   border-radius: 20px;
  //   padding: 10px;
  //   position: relative;
  //   right: 10px;
  //   background-color: #61c29c20;
  //   font-weight: 600;
  // }
}
.nome {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  width: 170px;
  word-break: break-word;
  cursor: pointer;
  line-height: 18px;

  // &:hover {
  //   color: #61c29c;
  // }
}
.fav-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fav {
  cursor: pointer;
  margin: 0 5px 0 10px;
  display: flex;
  width: 20px;
  height: 50px;
  align-items: center;

  .fav-img {
    height: 20px;
    width: 20px;
  }
}

.linha {
  width: 1px;
  height: 30px;
  background-color: #86878f;
}
.descricao--shrink {
  font-weight: 400;
  font-size: 14px;
  width: 60%;
  margin: 0 20px;
}

@media (max-width: 1024px) {
  .informacao {
    height: fit-content;
  }
}

.checkbox {
  /* padding: 5px 0; */
  display: flex;
  justify-content: center;
  margin-top: 5px;
  /* background:rgba(255, 255, 0, 0.392); */
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  /* color: #fff; */
  width: 18px;
  height: 18px;
  border: 0.15em solid #1da0f2;
  border-radius: 5px;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
}

input:checked {
  background-color: #1da0f2;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.imagem-selec {
  height: 20px !important;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  color: #a7a7a7;
}
</style>
