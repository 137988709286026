<template>
  <div>
    <div class="content" v-if="liberaTela && msgErroModelo == ''">
      <div ref="pdfContent">
        <div class="header-box">
          <div class="container-header">
            <div class="header-text">
              <span class="text-top">
                {{ tituloCodModelo }}
              </span>
              <span class="text-desc">
                {{ tituloDescModelo }}
              </span>
            </div>
            <div class="header-icons">
              <span
                class="icon-btn"
                id="download-pdf"
                @mouseover="showPdf = true"
                @mouseout="showPdf = false"
                :class="{ 'button-disabled': liberaSpinner }"
                @click="generatePDF()"
              >
                <img
                  :src="showPdf ? iconePDFAzul : iconePDFBranco"
                  alt=""
                  class="icon-header"
                />
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'download-pdf'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Gerar PDF
              </b-tooltip>
              <span
                class="icon-btn"
                id="modelos-inicio"
                @mouseover="showIconeModelo = true"
                @mouseout="showIconeModelo = false"
                @click="goInicio"
              >
                <img
                  :src="showIconeModelo ? iconePasso2Azul : iconePasso2Branco"
                  alt=""
                  class="icon-header"
                />
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'modelos-inicio'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Nova consulta de modelos
              </b-tooltip>
            </div>
          </div>
          <img
            class="icon-param"
            :class="{ close: exibirParametros }"
            id="openParams"
            :src="iconParam"
            @click="exibirParametros = !exibirParametros"
          />
          <b-tooltip
            v-if="exibirTootipsConsultas"
            :target="'openParams'"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="window"
          >
            Exibir informações sobre a consulta
          </b-tooltip>
        </div>
        <div>
          <BlocoParametros
            v-if="exibirParametros"
            :ref="'blocopfParam'"
            :resultados="resultados.Consultas[0].Consulta.sumario_consulta"
            :codAgr="resultados.CodigoAgrupador"
            :ocultarDados="ocultarDados"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoInfoPJ1
            v-if="Object.keys(this.resultadosInfoPJ1).length != 0"
            ref="blocopjInfo1"
            :resultados="resultadosInfoPJ1.Consulta.atributos"
            :listaBlocos="listaBlocos"
            :codigo_agrupador="resultados.CodigoAgrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoPFDadosCadastrais
            v-if="Object.keys(this.resultadosDadosCadastrais).length != 0"
            ref="blocopfDadosCadastrais"
            :resultados="resultadosDadosCadastrais.Consulta.atributos"
            :listaBlocos="listaBlocos"
            :valorCPF="valorCPF"
            :nomeCompleto="nomeCompleto"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoScore
            v-if="
              Object.keys(this.resultadoModelo).length != 0 &&
              this.resultadoModelo.Consulta.atributos != undefined &&
              this.resultadoModelo.Consulta.atributos.score != undefined
            "
            :ref="'blocopfScore'"
            :resultados="resultadoModelo.Consulta.atributos"
            :listaBlocos="listaBlocos"
            :modeloSelecionado="modeloSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
          <BlocoRenda
            ref="blocopfRenda"
            v-if="
              Object.keys(this.resultadoModelo).length != 0 &&
              this.resultadoModelo.Consulta.atributos != undefined &&
              (this.resultadoModelo.Consulta.atributos.renda_presumida != undefined ||
                this.resultadoModelo.Consulta.atributos.faturamento_presumido !=
                  undefined)
            "
            :resultados="resultadoModelo.Consulta.atributos"
            :listaBlocos="listaBlocos"
            :modeloSelecionado="modeloSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
          <BlocoPFMapaRiscos
            v-if="Object.keys(this.resultadosMapaRiscos).length != 0"
            ref="blocopfMapaRiscos"
            :resultados="resultadosMapaRiscos.Consulta.atributos"
            :listaBlocos="listaBlocos"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoInfoPJ2
            v-if="Object.keys(this.resultadosInfoPJ2).length != 0"
            ref="blocopjInfo2"
            :resultados="resultadosInfoPJ2.Consulta.atributos"
            :listaBlocos="listaBlocos"
            :codigo_agrupador="resultados.CodigoAgrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoKYC
            v-if="Object.keys(this.resultadosKYC).length != 0"
            ref="blocopjKyc"
            :resultadoConsultaAdd="resultadosKYC"
            :listaBlocos="listaBlocos"
            :codigo_agrupador="resultados.CodigoAgrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoPJAlertas
            ref="blocopjAlertas"
            v-if="liberaBlocoAlerta"
            :listaBlocos="listaBlocos"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <PrefTooltipTela @recebePrefTooltips="recebePrefTooltips" />
        </div>
      </div>
    </div>
    <div v-else-if="msgErroModelo != ''" class="nao_encontrado">
      <span class="material-icons-outlined sad">sentiment_dissatisfied</span>

      {{ msgErroModelo }}
    </div>
    <div class="spinnerBG" v-if="liberaSpinner">
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { mapActions, mapWritableState } from "pinia";
import { mascaras } from "@/helpers/mascaras";
import { produtosStore } from "@/stores/produtos";

import Loading from "@/components/Loading.vue";

import BlocoParametros from "@/components/Modelos/BlocoParametros.vue";
import BlocoScore from "@/components/Modelos/BlocoScore.vue";
import BlocoRenda from "@/components/Modelos/BlocoRenda.vue";
import BlocoInfoPJ1 from "@/components/Modelos/BlocoInfoPJ1.vue";
import BlocoKYC from "@/components/Modelos/BlocoKYC.vue";
import BlocoInfoPJ2 from "@/components/Modelos/BlocoInfoPJ2.vue";
import BlocoPJAlertas from "@/components/Modelos/BlocoPJAlertas.vue";

import BlocoPFDadosCadastrais from "@/components/SuperBox/BlocoPFDadosCadastrais.vue";
import BlocoPFMapaRiscos from "@/components/SuperBox/BlocoPFMapaRiscos.vue";
import PrefTooltipTela from "@/pages/MinhaConta/PrefTooltipTela.vue";

import { createPDFZip, createPDF } from "@/services/planilha.service.js";

export default {
  name: "ResultadosModelosHistorico",

  props: {
    resultados: Object,
  },

  data() {
    return {
      listaBlocos: [],
      resultadoModelo: {},
      resultadosDadosCadastrais: {},
      resultadosMapaRiscos: {},
      resultadosInfoPJ1: {},
      resultadosInfoPJ2: {},
      resultadosKYC: {},
      modeloSelecionado: {},
      liberaTela: false,
      liberaSpinner: false,
      msgErroModelo: "",
      iconParam: "/img/superbox/icon-parametros.svg",
      fundoHeader: "/img/modelos/Topo_Modelos.png",

      iconePDFAzul: "/img/modelos/icone-pdfAzul.png",
      iconePDFBranco: "/img/modelos/icone-pdfBranco.png",
      iconePasso2Azul: "/img/modelos/icone-novaConsultaAzul.png",
      iconePasso2Branco: "/img/modelos/icone-novaConsultaBranco.png",

      showPdf: false,
      showIconeModelo: false,
      exibirParametros: false,
      tituloCodModelo: "",
      tituloDescModelo: "",

      tipoDocumento: "",
    };
  },
  components: {
    BlocoParametros,
    BlocoScore,
    BlocoRenda,
    BlocoPFDadosCadastrais,
    BlocoPFMapaRiscos,
    Loading,
    BlocoInfoPJ1,
    BlocoInfoPJ2,
    BlocoPJAlertas,
    PrefTooltipTela,
    BlocoKYC,
  },

  async mounted() {
    this.reset();

    await this.getModelos();

    this.constroiBlocos();
    this.montaTitulo();

    this.ocultarDados = await this.syncPreferenciaOcultarDados();
    this.exibirTootipsConsultas = await this.syncPreferenciaConsultasTooltips();
    this.tipoDownloadConsulta = await this.syncPreferenciaTipoDownload();

    this.$nextTick(() => {
      this.abreBlocos();
    });
  },

  methods: {
    montaTitulo() {
      var tipoPessoa = "";

      if (this.tipoDocumento == "Física") {
        tipoPessoa = "PF";
      } else if (this.tipoDocumento == "Jurídica") {
        tipoPessoa = "PJ";
      }

      this.tituloCodModelo = `Modelo ${tipoPessoa}: ${this.modeloSelecionado.Valor}`;
      this.tituloDescModelo = this.modeloSelecionado.Descricao;
    },

    reset() {
      this.liberaTela = false;
      this.listaBlocos = [];
      this.resultadoModelo = {};
      this.resultadosDadosCadastrais = {};
      this.resultadosMapaRiscos = {};
      this.resultadosInfoPJ1 = {};
      this.resultadosInfoPJ2 = {};
      this.resultadosKYC = {};
      this.modeloSelecionado = {};
    },

    constroiBlocos() {
      this.liberaSpinner = true;
      let idBloco = 1;
      let contadorBloco = 1;

      const blocoScore = this.resultados.Consultas.find(
        (objeto) => objeto.Consulta.sumario_consulta.cod_produto === "v3/modelo"
      );

      if (blocoScore != undefined) {
        this.resultadoModelo = blocoScore;
      }

      if (blocoScore.Consulta.erro != undefined) {
        this.msgErroModelo = blocoScore.Consulta.erro.erro;
      }

      if (this.resultados.Consultas[0].Consulta.atributos != undefined) {
        this.modeloSelecionado = {
          Valor: blocoScore.Consulta.sumario_consulta.parametros.cod_ref,
        };

        let modeloEncontrado = this.modelos.find(
          (modelo) => modelo.Valor === this.modeloSelecionado.Valor
        );

        if (modeloEncontrado) {
          this.modeloSelecionado = {
            Valor: blocoScore.Consulta.sumario_consulta.parametros.cod_ref,
            Descricao: modeloEncontrado.Descricao,
            TipoPessoaConsulta: modeloEncontrado.TipoPessoaConsulta,
          };
        }

        const bloco1Selecionado = this.resultados.Consultas.find(
          (objeto) =>
            objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXSUPPF001"
        );

        if (bloco1Selecionado != undefined) {
          this.resultadosDadosCadastrais = bloco1Selecionado;
        }

        const bloco5Selecionado = this.resultados.Consultas.find(
          (objeto) =>
            objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXSUPPF005"
        );
        if (bloco5Selecionado != undefined) {
          this.resultadosMapaRiscos = bloco5Selecionado;
        }

        this.tipoDocumento = this.modeloSelecionado.TipoPessoaConsulta;

        if (this.tipoDocumento == "Jurídica" || this.tipoDocumento == "Indiferente") {
          var bloco1PJ = this.resultados.Consultas.find(
            (objeto) =>
              objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXSUPPJ001"
          );
          if (bloco1PJ != undefined) {
            this.resultadosInfoPJ1 = bloco1PJ;
          }

          var bloco2PJ = this.resultados.Consultas.find(
            (objeto) =>
              objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXSUPPJ002"
          );
          if (bloco2PJ != undefined) {
            this.resultadosInfoPJ2 = bloco2PJ;
          }

          var blocoKYC1 = this.resultados.Consultas.find(
            (objeto) =>
              objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXKYC001"
          );
          if (blocoKYC1 != undefined) {
            this.resultadosKYC["BOXKYC001"] = blocoKYC1.Consulta;
          }
          var blocoKYC2 = this.resultados.Consultas.find(
            (objeto) =>
              objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXKYC002"
          );
          if (blocoKYC2 != undefined) {
            this.resultadosKYC["BOXKYC002"] = blocoKYC2.Consulta;
          }
          var blocoKYC3 = this.resultados.Consultas.find(
            (objeto) =>
              objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXKYC003"
          );
          if (blocoKYC3 != undefined) {
            this.resultadosKYC["BOXKYC003"] = blocoKYC3.Consulta;
          }
          var blocoKYC4 = this.resultados.Consultas.find(
            (objeto) =>
              objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXKYC004"
          );
          if (blocoKYC4 != undefined) {
            this.resultadosKYC["BOXKYC004"] = blocoKYC4.Consulta;
          }
        }

        if (bloco1PJ) {
          const bloco1 = {
            box: this.resultadosInfoPJ1.Consulta.sumario_consulta.parametros.cod_ref,
            id: idBloco++,
            titulo: `Bloco 0${contadorBloco++}`,
            descricao: "Informações Cadastrais",
            imagem: "/img/superbox/bloco-dados-cadastrais.png",
          };
          this.listaBlocos.push(bloco1);
        }

        if (bloco1Selecionado) {
          const bloco1 = {
            box: this.resultadosDadosCadastrais.Consulta.sumario_consulta.parametros
              .cod_ref,
            id: idBloco++,
            titulo: `Bloco 0${contadorBloco++}`,
            descricao: "Dados Cadastrais",
            imagem: "/img/superbox/bloco-dados-cadastrais.png",
          };
          this.listaBlocos.push(bloco1);
        }

        if (blocoScore) {
          if (blocoScore.Consulta.atributos.score != undefined) {
            const blocoModelo = {
              box: this.resultadoModelo.Consulta.sumario_consulta.parametros.cod_ref,
              id: idBloco++,
              titulo: `Bloco 0${contadorBloco++}`,
              descricao: "Avaliação por Score",
              imagem: "/img/modelos/icone-bloco-score.png",
            };
            this.listaBlocos.push(blocoModelo);
          }

          if (
            blocoScore.Consulta.atributos.renda_presumida != undefined ||
            blocoScore.Consulta.atributos.faturamento_presumido != undefined
          ) {
            const blocoModelo = {
              box: this.resultadoModelo.Consulta.sumario_consulta.parametros.cod_ref,
              id: idBloco++,
              titulo: `Bloco 0${contadorBloco++}`,
              descricao: "Valores estimados",
              imagem: "/img/modelos/icone-bloco-score.png",
            };
            this.listaBlocos.push(blocoModelo);
          }
        }

        if (bloco5Selecionado) {
          const bloco5 = {
            box: this.resultadosMapaRiscos.Consulta.sumario_consulta.parametros.cod_ref,
            id: idBloco++,
            titulo: `Bloco 0${contadorBloco++}`,
            descricao: "Mapa Geral de Riscos",
            imagem: "/img/superbox/bloco-mapa-riscos.png",
          };
          this.listaBlocos.push(bloco5);
        }

        if (bloco2PJ) {
          const bloco3 = {
            box: this.resultadosInfoPJ2.Consulta.sumario_consulta.parametros.cod_ref,
            id: idBloco++,
            titulo: `Bloco 0${contadorBloco++}`,
            descricao: "Detalhamentos Cadastrais da Matriz",
            imagem: "/img/superbox/bloco-conexoes-empresas.png",
          };
          this.listaBlocos.push(bloco3);
        }

        if (blocoKYC1) {
          const bloco1 = {
            box: "BOXKYC001",
            id: idBloco++,
            titulo: `Bloco 0${contadorBloco++}`,
            descricao: "KYC & Due Diligence",
            imagem: "/img/modelos/icone-bloco-score.png",
          };
          this.listaBlocos.push(bloco1);
        }

        if (this.liberaBlocoAlerta) {
          const bloco4 = {
            box: "BOXSUPPJ009",
            id: idBloco++,
            titulo: `Bloco 0${contadorBloco++}`,
            descricao: "Alertas Gerais",
            imagem: "/img/superbox/bloco-alertas-gerais.png",
          };
          this.listaBlocos.push(bloco4);
        }
      }

      this.liberaTela = true;
      this.liberaSpinner = false;
    },

    abreBlocos() {
      if (
        this.resultadoModelo.Consulta.atributos != undefined &&
        this.resultadoModelo.Consulta.atributos.score != undefined
      ) {
        this.$refs[`blocopfScore`].abreEsteBloco();
      }
      if (
        this.resultadoModelo.Consulta.atributos != undefined &&
        (this.resultadoModelo.Consulta.atributos.renda_presumida != undefined ||
          this.resultadoModelo.Consulta.atributos.faturamento_presumido != undefined)
      ) {
        this.$refs[`blocopfRenda`].abreEsteBloco();
      }

      if (this.resultadosDadosCadastrais.Consulta != undefined) {
        this.$refs[`blocopfDadosCadastrais`].abreEsteBloco();
      }

      if (this.resultadosMapaRiscos.Consulta != undefined) {
        this.$refs[`blocopfMapaRiscos`].abreEsteBloco();
      }
      if (this.resultadosInfoPJ1.Consulta != undefined) {
        this.$refs[`blocopjInfo1`].abreEsteBloco();
      }
      if (this.resultadosInfoPJ2.Consulta != undefined) {
        this.$refs[`blocopjInfo2`].abreEsteBloco();
      }
      if (Object.keys(this.resultadosKYC).length != 0) {
        this.$refs[`blocopjKyc`].abreEsteBloco();
      }
      if (this.liberaBlocoAlerta) {
        this.$refs[`blocopjAlertas`].abreEsteBloco();
      }
    },

    async generatePDF() {
      this.liberaSpinner = true;
      this.abreBlocos();
      this.exibirParametros = true;

      await this.viewMapsAndReturn();
      const targetElement = this.$refs.pdfContent;
      const codAgr = this.resultados.CodigoAgrupador;
      let nomeArquivo = `outbox-modelo-${codAgr}`;

      if (this.tipoDownloadConsulta == "zip") {
        createPDFZip(targetElement, nomeArquivo);
      } else {
        createPDF(targetElement, nomeArquivo);
      }
      setTimeout(() => {
        this.liberaSpinner = false;
        this.exibirParametros = false;
      }, 3000);
    },

    async viewMapsAndReturn() {
      const maps = document.getElementsByClassName("vue-map-container");
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      for (const map of maps) {
        map.scrollIntoView();
        await delay(2000);
      }
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },

    goInicio() {
      this.$router.push("/consultas/modelos/0");
    },

    recebePrefTooltips(pref) {
      this.exibirTootipsConsultas = pref;
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaOcultarDados",
      "syncPreferenciaConsultasTooltips",
      "syncPreferenciaTipoDownload",
    ]),

    ...mapActions(produtosStore, ["getModelos"]),
  },

  computed: {
    valorCPF() {
      if (this.resultadosDadosCadastrais.Consulta != undefined) {
        var cpf = mascaras.cpf(
          this.resultadosDadosCadastrais.Consulta.sumario_consulta.parametros.cpf
        );
        if (this.ocultarDados) {
          return cpf.replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/, "***.$2.$3-**");
        } else {
          return cpf;
        }
      } else {
        return "";
      }
    },

    nomeCompleto() {
      var nome = this.resultadosDadosCadastrais.Consulta.atributos.bk_101_001_nome;
      if (this.ocultarDados) {
        let palavras = nome.split(" ");

        if (palavras.length > 1) {
          for (let i = 1; i < palavras.length; i++) {
            palavras[i] = palavras[i].replace(/\S/g, "*");
          }
        }
        return palavras.join(" ");
      } else {
        return nome;
      }
    },

    liberaBlocoAlerta() {
      if (
        this.resultados.TextoMetadados != undefined &&
        this.resultados.TextoMetadados != null
      ) {
        if (this.resultados.TextoMetadados.includes("bloco_alerta")) {
          return true;
        } else {
          return false;
        }
      } else {
        const bloco1 = this.resultados.Consultas.some(
          (objeto) =>
            objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXSUPPJ001"
        );
        const bloco2 = this.resultados.Consultas.some(
          (objeto) =>
            objeto.Consulta.sumario_consulta.parametros.cod_ref === "BOXSUPPJ002"
        );

        if (bloco1 && bloco2) {
          return true;
        } else {
          return false;
        }
      }
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      exibirTootipsConsultas: "exibirTootipsConsultas",
      tipoDownloadConsulta: "tipoDownloadConsulta",
    }),

    ...mapWritableState(produtosStore, {
      modelos: "modelos",
    }),
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nao_encontrado {
  margin: 30px 0;
  grid-column: 1/4;
  font-size: 1.5rem;
  font-weight: 300;

  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.sad {
  font-size: 3rem;
}

.header-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 960px;
}
.container-header {
  display: flex;
  width: 100%;
  height: 105px;
  max-width: 960px;
  background-image: url(/img/modelos/Topo_Modelos.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin: 10px 0 -20px 0;
}

.header-text {
  display: flex;
  flex-direction: column;
  color: #4356a5;
  width: 85%;
  align-items: flex-start;
  margin: 0 20px 0 25%;
  line-height: 21px;
  gap: 5px;

  .text-top {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }

  .text-desc {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
  }
}

.header-icons {
  display: flex;
  width: 15%;
  gap: 10px;
  margin: 0 10px;

  .icon-btn {
    width: 40px;
    height: 40px;
    // border: 2px solid #4356a5;
    border: 2px solid #1da0f2;
    background-color: #1da0f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 0 0 1px;
    transition: 0.4s;

    &:hover {
      transform: scale(1.05);
      background-color: transparent;
      border: 2px solid #4356a5;
    }
    &:active {
      transform: scale(0.9);
    }
  }

  .icon-header {
    width: 20px;
  }
}

.buttons-box {
  display: flex;
  width: 100%;
  max-width: 960px;
  align-items: flex-end;
  justify-content: flex-end;
}

.icon-param {
  height: 20px;
  margin: 10px 0 0 10px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  top: 30px;
  z-index: 10;

  &.close {
    transform: rotateY(180deg);
  }

  &:hover {
    transform: scale(1.05);
    &.close {
      transform: rotateY(180deg);
    }
  }
  &:active {
    transform: scale(0.9);
    &.close {
      transform: rotateY(180deg);
    }
  }
}

.loading {
  position: absolute;
  top: 10px;
  right: 45%;
}

.button-disabled {
  background-color: #6ca7d1;
  color: #ffffff;
  cursor: not-allowed;
  pointer-events: none;
  border: none;
}

.spinnerBG {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 125%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 52%;
  transform: scale(1.1);
  z-index: 10;
}
</style>
